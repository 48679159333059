import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Footer from "../../components/footer/footer";
import ReadMore from "../../components/text/readMore";
import AOS from "aos";
import bas from "../../Images/BELMA/bas3.png";
import box from "../../Images//BELMA/icone-food-box.png";
import shop from "../../Images/BELMA/2.png";
import camion from "../../Images/BELMA/icone-camion-b.png";
import viande from "../../Images/BELMA/3.jpg";
import magasin from "../../Images/BELMA/icone-boutique-b.png";
import principal from "../../Images/BELMA/principal.gif";
import boutique from "../../Images/BELMA//1.jpg";
import "aos/dist/aos.css";
import "./belma.css";
import { Modal } from "react-bootstrap";

export default function Belma({ sidebar }) {
  const [decouvrir1, setDecouvrir1] = useState(false);
  const [decouvrir2, setDecouvrir2] = useState(false);
  const [decouvrir3, setDecouvrir3] = useState(false);
  const [width, setWidth] = useState(0);
  const [showMaintenanceModal, setMaintenanceModal] = useState(true);
  const carousel = useRef();
  const navigate = useNavigate();

  const toggleMaintenanceModal = () => {
    setMaintenanceModal(!showMaintenanceModal);
  };

  const MaintenanceModal = ({ show, onHide }) => {
    return (
      <Modal show={show} onHide={onHide} dialogClassName="modal-dialog-centered">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <p className="m-0 pt-0 px-2 text-center">
          Chères clientes chers clients, <br />
          <br />
          Nous souhaitons vous informer que toute commande sur notre site internet sera traitée à partir du <strong style={{ fontWeight: 'bold' }}>3 septembre 2024</strong>. 
          <br /> <br />
Merci de votre compréhension, à bientôt 😊.  <br /> <br />   </p>
           
        </Modal.Body>
      </Modal>
    );
  };

  const handleClick = (numberDec) => {
    switch (numberDec) {
      case 1:
        setDecouvrir1((decouvrir1) => true);
        setDecouvrir2((decouvrir2) => false);
        setDecouvrir3((decouvrir3) => false);
        break;
      case 2:
        setDecouvrir1((decouvrir1) => false);
        setDecouvrir2((decouvrir2) => true);
        setDecouvrir3((decouvrir3) => false);
        break;
      case 3:
        setDecouvrir1((decouvrir1) => false);
        setDecouvrir2((decouvrir2) => false);
        setDecouvrir3((decouvrir3) => true);
        break;
      default:
        setDecouvrir1((decouvrir1) => false);
        setDecouvrir2((decouvrir2) => false);
        setDecouvrir3((decouvrir3) => false);
    }
  };
  const navigateTo = (link) => {
    navigate(link);
  };

  useEffect(() => {
    AOS.init({ duration: 70000 });
    setWidth(
      (width) => carousel.current.scrollWidth - carousel.current.offsetWidth
    );
  }, []);

  return (
    <div
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { minHeight: "100vh" }
      }
    >
     <MaintenanceModal
              show={showMaintenanceModal}
              onHide={toggleMaintenanceModal}
            />
      {/* Partie Scroll */}
      <img
        src={principal}
        className="img-fluid px-1"
        alt="gif principal"
        style={{ marginTop: "100px", borderRadius: "6px" }}
      /> 
      <ReadMore
        limit={130}
        text1="Chez Belma l’excellence n’est pas une option mais notre lifestyle."
        text2="En gastronomie nous pratiquons la règle des trois : le produit, le produit, le produit."
        text3="Attention nous sommes des maniaques de l´hygiène, indispensable en gastronomie !"
        text4="Belma c’est :"
        text5="Une sélection des meilleurs éleveurs en France."
        text6="Une recherche du bien-être animal."
        text7="Des produits issus d’agriculture biologique et dans le respect de l’environnement."
        text8="Nous ne lésinons pas sur le prix à payer afin d’avoir le meilleur pour nos gourmets"
        text9="Nous travaillons pas de viandes congelées, toujours fraiches et naturelles, sans produits chimiques, ni conservateurs."
        text10="Des créations culinaires uniques pour foodistas."
      />
      {/* Partie images avec redirection */}
      <div className="position-relative mx-1 my-5">
        {decouvrir1 ? (
          <img
            src={viande}
            className="img-fluid rounded photo-gris"
            alt="gris"
            onClick={() => handleClick(1)}
          />
        ) : (
          <img
            src={viande}
            className="img-fluid rounded"
            alt="normal"
            onClick={() => handleClick(1)}
          />
        )}
        {decouvrir1 && (
          <div
            className="position-absolute top-50 start-50 rounded hover_link text-center"
            onClick={() => navigateTo("/prestige_meats")}
          >
            <Link to="/prestige_meats">#MATURATION</Link>
          </div>
        )}
      </div>

      <div className="position-relative mx-1 mb-5">
        {decouvrir2 ? (
          <img
            src={shop}
            className="img-fluid rounded photo-gris"
            alt="gris"
            onClick={() => handleClick(2)}
          />
        ) : (
          <img
            src={shop}
            className="img-fluid rounded"
            alt="normal"
            onClick={() => handleClick(2)}
          />
        )}
        {decouvrir2 && (
          <div
            className="position-absolute top-50 start-50 rounded hover_link text-center"
            onClick={() => navigateTo("/belma_shop")}
          >
            <Link to="/belma_shop">#BELMASHOP</Link>
          </div>
        )}
      </div>

      <div className="position-relative mx-1 mb-5">
        {decouvrir3 ? (
          <img
            src={boutique}
            className="img-fluid rounded photo-gris"
            alt="gris"
            onClick={() => handleClick(3)}
          />
        ) : (
          <img
            src={boutique}
            className="img-fluid rounded"
            alt="normal"
            onClick={() => handleClick(3)}
          />
        )}
        {decouvrir3 && (
          <div
            className="position-absolute top-50 start-50 rounded hover_link text-center"
            onClick={() => navigateTo("/belma_story")}
          >
            <Link to="/belma_story">#LABOUTIQUE</Link>
          </div>
        )}
      </div>
      {/* Parti slide */}
      <h6 className="text-center" style={{ fontFamily: "titre" }}>
        Belma at home
      </h6>
      <motion.div ref={carousel} className="carousel">
        <motion.div
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
          whileTap={{ cursor: "grabbing" }}
          className="inner-carousel"
        >
          <motion.div className="item">
            <div className="step">
              <img
                src={magasin}
                alt="accueil shop"
                className="mx-auto d-block img-fluid"
              />
              <p
                className="p-0 m-0 pt-2 text-center"
                style={{ fontWeight: "bold", fontFamily: "titre" }}
              >
                1
              </p>
              <p
                className="p-0 m-0 pt-2 text-center"
                style={{ fontFamily: "texte" }}
              >
                Sélectionnez les viandes prestige et les créations qui vous
                plaisent
              </p>
            </div>
          </motion.div>
          <motion.div className="item">
            <div className="step">
              <img
                src={box}
                alt="accueil shop"
                className="mx-auto d-block img-fluid"
              />
              <p
                className="p-0 m-0 pt-2 text-center"
                style={{ fontWeight: "bold", fontFamily: "titre" }}
              >
                2
              </p>
              <p
                className="p-0 m-0 pt-2 text-center"
                style={{ fontFamily: "texte" }}
              >
                Nous découpons et préparons vos produits dès réception de votre
                commande pour un maximum de fraicheur
              </p>
            </div>
          </motion.div>
          <motion.div className="item">
            <div className="step">
              <img
                src={camion}
                alt="accueil shop"
                className="mx-auto d-block img-fluid"
              />
              <p
                className="p-0 m-0 pt-2 text-center"
                style={{ fontWeight: "bold", fontFamily: "titre" }}
              >
                3
              </p>
              <p
                className="p-0 m-0 pt-2 text-center"
                style={{ fontFamily: "texte" }}
              >
                Nous expédions et vous recevez votre belle commande pour
                déguster dans le confort de chez vous
              </p>
            </div>
          </motion.div>
          <motion.div className="item">
            <div className="plus_info text-center">
              <Link to="/delivery" style={{ fontFamily: "texte" }}>
                En savoir plus...
              </Link>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
      {/* Partie Image */}
      <div
        className="bg-success mt-5"
        style={{ height: "150px", maxWidth: "100vw" }}
      >
        <img
          src={bas}
          alt="accueil shop img-fluid"
          className="bg-success"
          style={{ height: "150px", maxWidth: "100vw" }}
        />
      </div>
      {/* Partie footer */}
      <Footer />
    </div>
  );
}
